import * as Sentry from '@sentry/react';
import {BrowserTracing} from '@sentry/tracing';

try {
  console.log('Init Sentry.');
  Sentry.init({
    dsn: 'https://ee12da37077249f6bf4f5f28ab1b95d5@o1315782.ingest.sentry.io/6605006',
    integrations: [new BrowserTracing()],
    // https://vitejs.dev/guide/env-and-mode.html#env-files
    enabled: import.meta && import.meta?.env?.MODE !== 'development',

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // it is recommended to adjust this value in production
    tracesSampleRate: 1.0,
  });
  console.log('Sentry Init Complete.');
} catch (err) {
  console.error('Failed to init Sentry.');
}
